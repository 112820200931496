import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './modules/home/home.component';
import { HowThisWorksComponent } from './modules/how-this-works/how-this-works.component';
import { AboutUsComponent } from './modules/about-us/about-us.component';
import { ContactUsComponent } from './modules/contact-us/contact-us.component';
import { OurRatesComponent } from './modules/our-rates/our-rates.component';
import { PrivacyPolicyComponent } from './modules/privacy-policy/privacy-policy.component';
import { QuotesComponent } from './modules/quotes/quotes.component';


const routes: Routes = [
  { path: 'home', redirectTo: '', component: HomeComponent, data: {animation: 'HomePage'} },
  { path: 'our-services', component: HowThisWorksComponent, data: {animation: 'HowThisWorksPage'} },
  { path: 'about-us', component: AboutUsComponent, data: {animation: 'AboutUsPage'} },
  { path: 'contact-us', component: ContactUsComponent, data: {animation: 'ContactUsPage'} },
  { path: 'our-rates', component: OurRatesComponent, data: {animation: 'OurRatesPage'} },
  { path: 'privacy-policy', component: PrivacyPolicyComponent, data: {animation: 'PrivacyPolicyPage'} },
  { path: 'quotes', component: QuotesComponent, data: {animation: 'GetQuotesPage'} },
  { path: '', component: HomeComponent, pathMatch: 'full'},
  { path: '**', redirectTo: '', component: HomeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
