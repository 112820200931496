import { Component, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-our-rates',
  templateUrl: './our-rates.component.html',
  styleUrls: ['./our-rates.component.scss']
})
export class OurRatesComponent implements OnInit, AfterViewInit {

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    window.scroll(0,0);
  }
}
