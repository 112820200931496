<!--

    MOBILE NAVIGATION BAR

-->

<div *ngIf="dropDownOpened" itemscope itemtype="https://schema.org/SiteNavigationElement" id="whole-screen" (click)="isClicked()"></div>

<div class="container-fluid row d-lg-none" id="navbar-container-row" aria-hidden="true">
  <div class="container-fluid row" id="navbar-container-col" style="margin:0;" aria-hidden="true">

    <div class="col-6 offset-3" id="logo-container" itemprop="name">
      <a itemprop="url" routerLink="/home"><img itemprop="image" alt="RollAwayz Dumpster Rental Logo" title="RollAwayz Dumpster Rental Logo" id="logo" src="assets/logo.png" /></a>
    </div>

    <!--
      NAVIGATION BAR
    -->

    <div class="col-2 offset-1 col-xl-1 offset-xl-2" ngbDropdown [autoClose]="true" id="ngbDropdown" placement="bottom-right" #myDrop aria-label="Drop down menu.">

      <button class="btn shadow-none" id="dropdownBasic" ngbDropdownToggle aria-label="Button for drop down menu."><i class="fas fa-bars fa-2x"></i></button>

      <div ngbDropdownMenu aria-labelledby="dropdownBasic" (click)="isClicked()">

        <div class="container-fluid user-nav" itemprop="name" aria-label="User nav option.">
          <a itemprop="url" class="user-nav" routerLink="/home" aria-labelledby="Home">Home</a>
        </div>

        <div class="container-fluid user-nav" itemprop="name" itemprop="name" aria-label="User nav option.">
          <a itemprop="url" class="user-nav" routerLink="/our-rates" aria-labelledby="Our Rates">Our Rates</a>
        </div>

        <div class="container-fluid user-nav" itemprop="name" aria-label="User nav option.">
          <a itemprop="url" class="user-nav" routerLink="/quotes" aria-labelledby="Get a Quote">Get a Quote</a>
        </div>

        <div class="container-fluid user-nav" itemprop="name" aria-label="User nav option.">
          <a itemprop="url" class="user-nav" routerLink="/our-services" aria-labelledby="How This Works">Our Services</a>
        </div>

        <div class="container-fluid user-nav" itemprop="name" aria-label="User nav option.">
          <a itemprop="url" class="user-nav" routerLink="/about-us" aria-labelledby="About Us">About Us</a>
        </div>

        <div class="container-fluid user-nav" itemprop="name" aria-label="User nav option.">
          <a itemprop="url" class="user-nav" routerLink="/contact-us">Contact Us</a>
        </div>

        <!-- <div class="container-fluid user-nav" itemprop="name" aria-label="User nav option." style="color:red;">
          <a itemprop="url" class="user-nav" href="">Order Online</a>
        </div> -->

      </div>
    </div>

    <!--
      CONTACT INFORMATION
    -->

    <div class="col-12" id="contact">
      <div class="col-12 contact-info">
        <span class="col-md-4 contact-link">
          <i itemprop="image" class="fas fa-phone-alt"></i><a itemprop="url" href="tel:1-419-615-5886">   (419) 615-5886</a>
        </span>
        <span class="col-md-4 contact-link" itemprop="name">
          <i itemprop="image" class="far fa-envelope"></i>
          <a itemprop="url" href="mailto:rent@rollawayz.com">   rent@rollawayz.com</a>
        </span>
        <!-- <span class="col-md-4 contact-link bill" itemprop="name">
          <i itemprop="image" class="far fa-credit-card"></i>
          <a itemprop="url" href="">   Order Online</a>
        </span> -->
      </div>
    </div>
    <!-- .d-sm-block  -->
  </div>
</div>


<!--

    DESKTOP NAVIGATION BAR

-->

<div class="container-fluid row d-none d-lg-block" itemscope itemtype="https://schema.org/SiteNavigationElement" id="navbar-container-row" aria-hidden="true">
  <div class="row" style="margin:0;">
    <div class="col-lg-4 row user-nav-links" style="margin:0; display: block; text-align: right;" itemprop="name">
        <a itemprop="url" class="user-nav" routerLink="/" aria-labelledby="Home">Home</a>
        <a itemprop="url" class="user-nav" routerLink="/our-rates" aria-labelledby="Our Rates">Our Rates</a>
        <a itemprop="url" class="user-nav" routerLink="/quotes" aria-labelledby="Get a Quote">Get a Quote</a>
    </div>
    <div class="col-lg-4" id="logo-container" style="margin:0;">
      <a itemprop="url" routerLink="/home"><img itemprop="image" alt="RollAwayz Dumpster Rental Containers  Logo" title="RollAwayz Dumpster Rental Services Logo" id="logo" src="assets/logo.png" /></a>
    </div>
    <div class="col-lg-4 row user-nav-links" style="margin:0; display: block; text-align: left;" itemprop="name">
      <a itemprop="url" class="user-nav" routerLink="/our-services" aria-labelledby="Our Service">Our Services</a>
      <a itemprop="url" class="user-nav" routerLink="/about-us" aria-labelledby="About Us">About Us</a>
      <a itemprop="url" class="user-nav" routerLink="/contact-us" aria-labelledby="Contact Us">Contact Us</a>
  </div>
  </div>

  <!--
    CONTACT INFORMATION
  -->

  <div class="col-12" id="contact" itemscope itemtype="http://schema.org/LocalBusiness">
    <div class="contact-info">
      <span class="contact-link" itemprop="name">
        <i itemprop="image" class="fas fa-phone-alt"></i><a itemprop="telephone" href="tel:1-419-615-5886">  (419) 615-5886</a>
      </span>
      <span class="contact-link" itemprop="name">
        <i itemprop="image" class="far fa-envelope"></i>
        <a itemprop="email" href="mailto:rent@rollawayz.com">  rent@rollawayz.com</a>
      </span>
      <!-- <span class="contact-link" itemprop="name">
        <i itemprop="image" class="far fa-credit-card"></i>
        <a itemprop="url" href="">  Order Online</a>
      </span> -->
    </div>
  </div>

<div>
  



