<!doctype html>
<html lang="en">
  <head>
<meta name="description" content="About RollAwayz Dumpster Rental: We service residential, commercial, or industrial private properties to the Ottawa, Lima, Findlay, Columbus Grove and surrounding Northwest Ohio areas. Reserve your roll-off dumpster rental near you today!">
<meta property="og:description" content="About RollAwayz Roll-Off Dumpster Rentals: We service residential, commercial, and industrial private properties to the Northwestern Ohio area. Reserve your roll-off dumpster rental near you today!">
<meta name="twitter:description" content="About RollAwayz Roll-Off Dumpster Rentals: We service residential, commercial, and industrial private properties to the Northwestern Ohio area. Reserve your roll-off dumpster rental near you today!">
<meta property="og:type" content="website">
<meta property="og:site_name" content="RollAwayz Dumpster Rental ">
<meta property="og:title" content="About RollAwayz Dumpster Rental Containers in Northwestern Ohio | RollAwayz Dumpster Rentals">
<meta name="twitter:title" content="Roll-Off Dumpster Rentals Near Me | RollAwayz Dumpster Rental">
<meta name="viewport"content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
<meta name="mobile-web-app-capable" content="yes">
<meta name="apple-mobile-web-app-capable" content="yes">
<meta name="apple-mobile-web-app-status-bar-style" content="black-translucent">
<meta property="og:locale" content="en_US" />
<!-- Global site tag (gtag.js) - Google Analytics -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-DX8SHPVZ7M"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-DX8SHPVZ7M');
</script>
<link rel="canonical" href="https://www.rollawayzdumpsters.com/about-us" />
<link rel="yandex-tableau-widget" href="../../../assets/icons/yandex-browser-manifest.json">
<link rel="apple-touch-icon" sizes="57x57" href="../../../assets/icons/apple-touch-icon-57x57.png">
<link rel="apple-touch-icon" sizes="60x60" href="../../../assets/icons/apple-touch-icon-60x60.png">
<link rel="apple-touch-icon" sizes="72x72" href="../../../assets/icons/apple-touch-icon-72x72.png">
<link rel="apple-touch-icon" sizes="76x76" href="../../../assets/icons/apple-touch-icon-76x76.png">
<link rel="apple-touch-icon" sizes="114x114" href="../../../assets/icons/apple-touch-icon-114x114.png">
<link rel="apple-touch-icon" sizes="120x120" href="../../../assets/icons/apple-touch-icon-120x120.png">
<link rel="apple-touch-icon" sizes="144x144" href="../../../assets/icons/apple-touch-icon-144x144.png">
<link rel="apple-touch-icon" sizes="152x152" href="../../../assets/icons/apple-touch-icon-152x152.png">
<link rel="apple-touch-icon" sizes="180x180" href="../../../assets/icons/apple-touch-icon-180x180.png">
<link rel="apple-touch-icon" sizes="1024x1024" href="../../../assets/icons/apple-touch-icon-1024x1024.png">
<link rel="icon" type="image/png" sizes="192x192" href="../../../assets/icons/android-chrome-192x192.png">
<link rel="icon" type="image/png" sizes="32x32" href="../../../assets/icons/favicon-32x32.png">
<link rel="icon" type="image/png" sizes="16x16" href="../../../assets/icons/favicon-16x16.png">
<link rel="apple-touch-startup-image" media="(device-width: 320px) and (device-height: 480px) and (-webkit-device-pixel-ratio: 1)" href="../../../assets/icons/apple-touch-startup-image-320x460.png">
<link rel="apple-touch-startup-image" media="(device-width: 320px) and (device-height: 480px) and (-webkit-device-pixel-ratio: 2)" href="../../../assets/icons/apple-touch-startup-image-640x920.png">
<link rel="apple-touch-startup-image" media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)" href="../../../assets/icons/apple-touch-startup-image-640x1096.png">
<link rel="apple-touch-startup-image" media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)" href="../../../assets/icons/apple-touch-startup-image-750x1294.png">
<link rel="apple-touch-startup-image" media="(device-width: 414px) and (device-height: 736px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3)" href="../../../assets/icons/apple-touch-startup-image-1182x2208.png">
<link rel="apple-touch-startup-image" media="(device-width: 414px) and (device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3)" href="../../../assets/icons/apple-touch-startup-image-1242x2148.png">
<link rel="apple-touch-startup-image" media="(device-width: 768px) and (device-height: 1024px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 1)" href="../../../assets/icons/apple-touch-startup-image-748x1024.png">
<link rel="apple-touch-startup-image" media="(device-width: 768px) and (device-height: 1024px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 1)" href="../../../assets/icons/apple-touch-startup-image-768x1004.png">
<link rel="apple-touch-startup-image" media="(device-width: 768px) and (device-height: 1024px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2)" href="../../../assets/icons/apple-touch-startup-image-1496x2048.png">
<link rel="apple-touch-startup-image" media="(device-width: 768px) and (device-height: 1024px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2)" href="../../../assets/icons/apple-touch-startup-image-1536x2008.png">
<!-- Chrome, Firefox OS and Opera -->
<meta name="theme-color" content="#e43938">
<!-- Windows Phone -->
<meta name="msapplication-navbutton-color" content="#e43938">
<!-- iOS Safari -->
<meta name="apple-mobile-web-app-status-bar-style" content="#e43938">
  </head>
<div class="container-fluid row main" itemscope itemtype="https://schema.org/DumpsterRentals">
  <div class="bg-and-quotes col-12">
    <h1 itemprop="mainEntityOfPage"><b>About RollAwayz</b></h1>
  </div>

  <div class="container-fluid row about" itemscope itemtype="https://schema.org/AboutPage">
    <div class="col-12 box-image">
      <img alt="RollAwayz Dumpster Rental Shown With The Owner Image" title="RollAwayz Roll-Off Dumpster Bins Shown" 
            src="../../../assets/dumpster_fleet_12yards_16yards.jpg" loading="lazy" itemprop="primaryImageOfPage"/>
    </div>

    <div class="container-fluid row" id="paragraph-container" itemprop="mainContentOfPage">
      <div class="col-10 offset-1 col-md-6 offset-md-0 paragraph">
        <h3 itemprop="keywords">Who We Are</h3>
        <p itemprop="description">
            RollAwayz is a family-owned company that is here to serve all of your dumpster rental needs. We know that life can sometimes get interrupted 
            with moving, shifts in space, or downsizing and you become in a rush to throw out old items. The thought of needing a <b>clean</b>, 
            <b>reliable</b>, <b>affordable</b>, and <b>quick</b> dumpster to throw out items is all that is crossing your mind. This is where 
            RollAwayz Roll-Off Dumpster Rentals comes in. We offer affordable prices and the convenience of having the roll-off delivered to your spot! Our 
            professional drivers take the utmost care of your property while delivering them to your convenience. RollAwayz is your solution for moving, extending garage space, businesses, remodeling, renovations, 
            and so many more!
        </p>
      </div>

      <div class="col-10 offset-1 col-md-6 offset-md-0 paragraph">
        <h3 itemprop="keywords">Why Choose Us?</h3>
        <p itemprop="description">
            With our reliable trailer hauling system, we are able to drop off your dumpster virtually anywhere. Inclined driveways, backyards, parking spots, you name 
            it and we can accomplish it. Need your roll off out of the way quickly, or an extended period of time? Just get ahold of us and we can help you out! 
            Take the time you need to fill it, and we will do the rest. Whether it's scrap metal, shingles, siding, drywall, or trash - RollAwayz has a solution for your project. All of our dumpster have <b>Four</b> wheels on them - while most competitors do not. This means you will never have to worry about your driveway getting scratched up!
        </p>
      </div>
    </div>

  </div>
</div>