<!doctype html>
<html lang="en">
  <head>
<meta name="viewport"content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
<meta name="description" content="Our RollAwayz Dumpster Rental Services: RollAwayz Dumpster Rental servicing Lima, Findlay, Van Wert, Delphos, Fort Wayne, Dayton and the surrounding areas in Ohio. We are your local moving and mobile self storage container solution! Our portable self storage units make moving and storing easier.">
<meta property="og:description" content="Our RollAwayz Dumpster Rental Container Services: RollAwayz Dumpster Rental servicing Lima, Findlay, Van Wert, Delphos, Fort Wayne, Dayton and the surrounding areas in Ohio. We are your local moving and mobile self storage container solution! Our portable self storage units make moving and storing easier.">
<meta name="twitter:description" content="Our RollAwayz Dumpster Rental Unit Services: RollAwayz Dumpster Rental servicing Lima, Findlay, Van Wert, Delphos, Fort Wayne, Dayton and the surrounding areas in Ohio. We are your local moving and mobile self storage container solution! Our portable self storage units make moving and storing easier.">
<meta property="og:type" content="website">
<meta property="og:site_name" content="RollAwayz Dumpster Rental">
<meta name="twitter:card" content="summary">
<meta property="og:title" content="Our RollAwayz Dumpster Rental Services Northwestern Ohio| RollAwayz Dumpster Rental">
<meta name="twitter:title" content="Our RollAwayz Dumpster Rental Services in Ottawa, Findlay, Columbus Grove, Lima Ohio and the surrounding areas | Compare us to Bin-There-Dump-That® and Republic for Roll Off Dumpster Rentals | RollAwayz Dumpster Rental">
<meta name="mobile-web-app-capable" content="yes">
<meta name="apple-mobile-web-app-capable" content="yes">
<meta name="apple-mobile-web-app-status-bar-style" content="black-translucent">
<meta property="og:locale" content="en_US" />
<!-- Chrome, Firefox OS and Opera -->
<meta name="theme-color" content="#e43938">
<!-- Windows Phone -->
<meta name="msapplication-navbutton-color" content="#e43938">
<!-- iOS Safari -->
<meta name="apple-mobile-web-app-status-bar-style" content="#e43938">
<!-- Global site tag (gtag.js) - Google Analytics -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-DX8SHPVZ7M"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-DX8SHPVZ7M');
</script>
<link rel="canonical" href="https://www.rollawayzdumpsters.com/our-services" />
<link rel="yandex-tableau-widget" href="../../../assets/icons/yandex-browser-manifest.json">
<link rel="apple-touch-icon" sizes="57x57" href="../../../assets/icons/apple-touch-icon-57x57.png">
<link rel="apple-touch-icon" sizes="60x60" href="../../../assets/icons/apple-touch-icon-60x60.png">
<link rel="apple-touch-icon" sizes="72x72" href="../../../assets/icons/apple-touch-icon-72x72.png">
<link rel="apple-touch-icon" sizes="76x76" href="../../../assets/icons/apple-touch-icon-76x76.png">
<link rel="apple-touch-icon" sizes="114x114" href="../../../assets/icons/apple-touch-icon-114x114.png">
<link rel="apple-touch-icon" sizes="120x120" href="../../../assets/icons/apple-touch-icon-120x120.png">
<link rel="apple-touch-icon" sizes="144x144" href="../../../assets/icons/apple-touch-icon-144x144.png">
<link rel="apple-touch-icon" sizes="152x152" href="../../../assets/icons/apple-touch-icon-152x152.png">
<link rel="apple-touch-icon" sizes="180x180" href="../../../assets/icons/apple-touch-icon-180x180.png">
<link rel="apple-touch-icon" sizes="1024x1024" href="../../../assets/icons/apple-touch-icon-1024x1024.png">
<link rel="icon" type="image/png" sizes="192x192" href="../../../assets/icons/android-chrome-192x192.png">
<link rel="icon" type="image/png" sizes="32x32" href="../../../assets/icons/favicon-32x32.png">
<link rel="icon" type="image/png" sizes="16x16" href="../../../assets/icons/favicon-16x16.png">
<link rel="apple-touch-startup-image" media="(device-width: 320px) and (device-height: 480px) and (-webkit-device-pixel-ratio: 1)" href="../../../assets/icons/apple-touch-startup-image-320x460.png">
<link rel="apple-touch-startup-image" media="(device-width: 320px) and (device-height: 480px) and (-webkit-device-pixel-ratio: 2)" href="../../../assets/icons/apple-touch-startup-image-640x920.png">
<link rel="apple-touch-startup-image" media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)" href="../../../assets/icons/apple-touch-startup-image-640x1096.png">
<link rel="apple-touch-startup-image" media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)" href="../../../assets/icons/apple-touch-startup-image-750x1294.png">
<link rel="apple-touch-startup-image" media="(device-width: 414px) and (device-height: 736px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3)" href="../../../assets/icons/apple-touch-startup-image-1182x2208.png">
<link rel="apple-touch-startup-image" media="(device-width: 414px) and (device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3)" href="../../../assets/icons/apple-touch-startup-image-1242x2148.png">
<link rel="apple-touch-startup-image" media="(device-width: 768px) and (device-height: 1024px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 1)" href="../../../assets/icons/apple-touch-startup-image-748x1024.png">
<link rel="apple-touch-startup-image" media="(device-width: 768px) and (device-height: 1024px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 1)" href="../../../assets/icons/apple-touch-startup-image-768x1004.png">
<link rel="apple-touch-startup-image" media="(device-width: 768px) and (device-height: 1024px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2)" href="../../../assets/icons/apple-touch-startup-image-1496x2048.png">
<link rel="apple-touch-startup-image" media="(device-width: 768px) and (device-height: 1024px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2)" href="../../../assets/icons/apple-touch-startup-image-1536x2008.png">
</head>
<div class="container-fluid main" itemscope itemtype="https://schema.org/Service">
  <h1 itemprop="name">How It Works</h1>
  <p itemprop="slogan" class="col-10 offset-1 col-lg-6 offset-lg-3">
    Our goal at RollAwayz is to provide convenient and flexible waste removal solutions. We believe that ridding of items and trash has never been 
    easier! Take advantage of one of our dumpster rental solutions by ordering a sleek dumpster to your house or job site.
  </p>
  <!-- <p>
    <br>We will need to know:
    <li>The size of dumpster you desire.</li>
    <li>When & Where you would like us to deliver the roll-off.</li>
    <li>What material type will be going in the dumpster.</li>
  </p> -->

  <!-- <div class="container-fluid infograph">
    <img itemprop="image" id="step-process" src="../../../assets/bg/RollAwayz-Storage-3-Step-Process.jpg" loading="lazy" alt="RollAwayz Dumpster Rental Solution 3-Step Process"/>
  </div> -->

  <div class="container-fluid content">
    <div class="col-10 offset-1 content-card">
      <h2 itemprop="providesService">Get Your Roll-Off Dumpster Quote</h2>
      <i itemprop="image" class="col-12 fas fa-truck-loading"></i>
      <p itemprop="description">
        Call/Text any of our dumpster rental professionals who will direct you through the process. Our team will assist you throughout.
        <br> You can also visit our "Get A Quote" page to receive a free quote and reserve your dumpster today.
      </p>
      <!-- <p itemprop="description">
        We deliver the dumpster directly to your location. Our roll-off setup is capable of placing a container 
        in a way that is most convenient for your location - whether it's in a driveway, a parking spot, on an incline, or anywhere the roll-off 
        will fit! 
      </p> -->

      <hr>
  
      <h2 itemprop="providesService">Place The Order</h2>
      <i itemprop="image" class="col-12 fas fa-map-marker-alt"></i>
      <p itemprop="description">
        Once you make your dumpster rental order, hang tight for delivery day. In the meantime, please ensure to have enough room available for the drop-off location and items removed.
      </p>

      <hr>
  
      <h2 itemprop="providesService">Fill The Can</h2>
      <i itemprop="image" class="col-12 fas fa-dumpster"></i>
      <p itemprop="description">
        Fill up your roll off dumpster with your building waste, garbage or clutter, appliances, furniture, or any other of the allowed items!
      </p>

      <hr>
  
      <h2 itemprop="providesService">Removal Of the Can</h2>
      <i itemprop="image" class="col-12 fas fa-truck-moving"></i>
      <p itemprop="description">
        Once you are done loading your dumpster with items, feel free to give our team a call to get your dumpster promptly removed. <br>Can't get any easier than this!
      </p>
      <span itemprop="identifier">
        <a class="info-links" routerLink="/our-rates" itemprop="url">View Our Rates</a>
        <a class="info-links" routerLink="/quotes" itemprop="url">Get A Quote</a>
     </span>

    </div>
  </div>     
  <p style="margin-top: 2%;" itemprop="slogan" class="col-10 offset-1 col-lg-6 offset-lg-3">
    Whether you’re a home builder, remodeler, roofer, window and siding specialist,
    commercial contractor, or an every day residential person - RollAwayz will save you time and all of the headaches
    with your roll-off rental needs.
    <br>
    Our friendly waste professionals can deliver our services to your site with just one call. <br><br>We have the
    capability and desire to provide you with the exceptional service you deserve. 
    <br><br><b>We Promise That.</b>
  </p>

</div>

