import { Component, ViewChild, OnInit } from '@angular/core';
import { NavbarComponent } from './modules/layout/navbar/navbar.component';
import { RouterOutlet } from '@angular/router';
import { slideInAnimation } from './app.transitions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideInAnimation]
})
export class AppComponent {

  @ViewChild(NavbarComponent, { static: false })
  public navbar: NavbarComponent;

  public title = 'RollAwayz-dev';

  constructor() {
  }


  
  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }
  
}
