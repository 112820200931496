import { Component, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-how-this-works',
  templateUrl: './how-this-works.component.html',
  styleUrls: ['./how-this-works.component.scss']
})
export class HowThisWorksComponent implements OnInit, AfterViewInit {

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    window.scroll(0,0);
  }

}
