<div class="container-fluid row" id="main-container" itemscope itemtype="https://schema.org/WPFooter">

  <h4 class="col-12" id="storage-quote" itemprop="headline">"We’ll Roll Away Your Trash Today!"</h4>


  <div class="col-6 col-md-4 offset-sm-0 contact-info">
    <h2 itemprop="name"><b>RollAwayz Dumpster Rentals</b></h2>
    <a href="https://www.google.com/maps?daddr=1440 Bellefontaine Ave, Lima, OH 45804"  
        onclick="window.open('https://www.google.com/maps/place/RollAwayz/@40.7281124,-84.0788009,17z/data=!3m1!4b1!4m5!3m4!1s0x883eed1867c54dc3:0xd82b82978d5b3723!8m2!3d40.7281915!4d-84.0765454'); return false;"
        style="color: rgb(224, 224, 224);">
      <span itemtype="http://schema.org/PostalAddress">
        <span itemprop="streetAddress">1440 Bellefontaine Ave</span><br>
        <span itemprop="addressLocality">Lima</span>,
        <span itemprop="addressRegion">OH</span>,
        <span itemprop="postalCode">45804</span>
        <br>
        <span itemprop="telephone"><a href="tel:1-419-615-5886">(419) 615-5886</a></span>
        <span itemprop="addressCountry" class="hidden">US</span><br><br>
      </span>
    </a>
  </div>


  <!-- SOCIAL MEDIA ICONS IN BETWEEN CONTACT AND BUSINESS INFO MD SCREEN AND ABOVE -->
  <div class="col-md-4 d-none d-md-block social-following" itemscope itemtype="https://schema.org/Organization">
      <br>
      <div class="col-12 row" style="padding:0;margin:0;">
        <div class="col-12 icon">
          <a itemprop="sameAs" href="https://www.facebook.com/RollAwayz-108146137749414/">
            <i itemprop="image" class="fab fa-facebook-square" style="color:white;"></i>
          </a>
        </div>
        <div class="col-12 icon">
          <a itemprop="sameAs" href="https://www.google.com/search?q=RollAwayz+1440+Bellefontaine+Ave+Lima%2C+OH+45804">
            <i itemprop="image" class="fab fa-google-plus-square" style="color:white; text-align: center;"></i>
          </a>
        </div>
    </div>
  </div>

  <div class="col-6 col-md-4 business-info" itemscope itemtype="https://schema.org/LocalBusiness">
    <h2 itemprop="description"> <b>Hours Of Operation</b> </h2>
    <h4 itemprop="openingHours">Monday - Friday</h4>
    <h4 itemprop="openingHours"><strong> 8:30 AM - 6:00 PM </strong></h4>
    <h4 itemprop="openingHours">Saturday</h4>
    <h4 itemprop="openingHours"><strong> 8:30 AM - 4:00 PM </strong></h4>
  </div>

  <!-- SOCIAL MEDIA ICONS IN BETWEEN CONTACT AND BUSINESS INFO SM SCREEN AND BELOW -->
  <div class="col-12 d-md-none social-following">
      <br>
      <!--<h4>Follow Us!</h4>-->
      <div class="col-12 row" style="padding:0;margin:0;">
        <div class="col-3 offset-3 icon">
          <a itemprop="sameAs" href="https://www.facebook.com/RollAwayz-108146137749414/">
            <i itemprop="image" class="fab fa-facebook-square" style="color:white;"></i>
          </a>
        </div>
        <div class="col-3 icon">
          <a itemprop="sameAs" href="https://g.page/rollawayz">
            <i itemprop="image" class="fab fa-google-plus-square" style="color:white; text-align: center;"></i>
          </a>
        </div>
    </div>
  </div>

  <div class="col-12 row quick-links" itemscope itemtype="https://schema.org/URL" style="padding:0; margin: 0;">
    <!--<h5>SiteMap</h5>-->
    <div class="col-12 row sitemap" style="text-align: center;"> 
      <span><a itemprop="significantLink" routerLink="/home">Home</a></span>
      
      <span><a itemprop="significantLink" routerLink="/our-rates">Our Rates</a></span>

      <span><a itemprop="significantLink" routerLink="/quotes">Quotes</a></span>

      <span><a itemprop="significantLink" routerLink="/our-services">Our Services</a></span>

      <span><a itemprop="significantLink" routerLink="/about-us">About Us</a></span>
      
      <span class="last"><a itemprop="significantLink" routerLink="/contact-us">Contact Us</a></span>
    </div>
  </div>
</div>
<div class="container-fluid" style="overflow-x: hidden;" id="privacy-policy-and-terms" itemprop="description"><p>© 2021 RollAwayz. All Rights Reserved. <a itemprop="significantLink" href="/privacy-policy/">Privacy</a></p></div>
