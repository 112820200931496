import { Component, OnInit, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { ViewEncapsulation } from '@angular/compiler/src/core';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnInit {

  @ViewChild(NgbDropdown, {static: true}) 
  public ngbDropdown: NgbDropdown;

  public dropDownOpened: boolean;
  test = false;


  constructor() {
  }

  ngOnInit() {
    this.ngbDropdown.openChange.subscribe((event) => {
      if (event === true) {
        this.dropDownOpened = true;
      } else {
        this.dropDownOpened = false;
        this.ngbDropdown.close();
      }
    });
  }

  isClicked() {
    this.ngbDropdown.close();
  }

}
