<!doctype html>
<html lang="en">
  <head>
<meta name="description" content="Get A Quote Today! RollAwayz Dumpster Rental : RollAwayz Dumpster Rental in the Lima, Findlay, Van Wert, Delphos, Fort Wayne, Dayton Ohio and surrounding areas. We are your local moving and mobile self storage container solution! Our portable self storage units make moving and storing easier.">
<meta property="og:description" content="Get A Quote Today! RollAwayz Dumpster Rental : RollAwayz Dumpster Rental in the Northwestern Ohio area. We are your local moving and mobile self storage container solution! Our portable self storage units make moving and storing easier.">
<meta name="twitter:description" content="Check Out Our Quotes! RollAwayz Dumpster Rental : RollAwayz Dumpster Rental in the Northwestern Ohio area. We are your local moving and mobile self storage container solution! Our portable self storage units make moving and storing easier.">
<meta property="og:type" content="website">
<meta property="og:site_name" content="RollAwayz Dumpster Rental">
<meta name="twitter:card" content="summary">
<meta property="og:title" content="Our RollAwayz Dumpster Rental Quotes in Northwestern Ohio | RollAwayz Dumpster Rental">
<meta name="twitter:title" content="RollAwayz Dumpster Can Rental Quotes in Northwestern Ohio | Compare us to Bin-There-Dump-That® and Republic for Roll Off Dumpster Rentals | RollAwayz Dumpster Rentals">
<meta name="viewport"content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
<meta name="mobile-web-app-capable" content="yes">
<meta name="apple-mobile-web-app-capable" content="yes">
<meta name="apple-mobile-web-app-status-bar-style" content="black-translucent">
<meta property="og:locale" content="en_US" />
<!-- Chrome, Firefox OS and Opera -->
<meta name="theme-color" content="#e43938">
<!-- Windows Phone -->
<meta name="msapplication-navbutton-color" content="#e43938">
<!-- iOS Safari -->
<meta name="apple-mobile-web-app-status-bar-style" content="#e43938">
<!-- Global site tag (gtag.js) - Google Analytics -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-DX8SHPVZ7M"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-DX8SHPVZ7M');
</script>
<link rel="canonical" href="https://www.rollawayzdumpsters.com/quotes" />
<link rel="yandex-tableau-widget" href="../../../assets/icons/yandex-browser-manifest.json">
<link rel="apple-touch-icon" sizes="57x57" href="../../../assets/icons/apple-touch-icon-57x57.png">
<link rel="apple-touch-icon" sizes="60x60" href="../../../assets/icons/apple-touch-icon-60x60.png">
<link rel="apple-touch-icon" sizes="72x72" href="../../../assets/icons/apple-touch-icon-72x72.png">
<link rel="apple-touch-icon" sizes="76x76" href="../../../assets/icons/apple-touch-icon-76x76.png">
<link rel="apple-touch-icon" sizes="114x114" href="../../../assets/icons/apple-touch-icon-114x114.png">
<link rel="apple-touch-icon" sizes="120x120" href="../../../assets/icons/apple-touch-icon-120x120.png">
<link rel="apple-touch-icon" sizes="144x144" href="../../../assets/icons/apple-touch-icon-144x144.png">
<link rel="apple-touch-icon" sizes="152x152" href="../../../assets/icons/apple-touch-icon-152x152.png">
<link rel="apple-touch-icon" sizes="180x180" href="../../../assets/icons/apple-touch-icon-180x180.png">
<link rel="apple-touch-icon" sizes="1024x1024" href="../../../assets/icons/apple-touch-icon-1024x1024.png">
<link rel="icon" type="image/png" sizes="192x192" href="../../../assets/icons/android-chrome-192x192.png">
<link rel="icon" type="image/png" sizes="32x32" href="../../../assets/icons/favicon-32x32.png">
<link rel="icon" type="image/png" sizes="16x16" href="../../../assets/icons/favicon-16x16.png">
<link rel="apple-touch-startup-image" media="(device-width: 320px) and (device-height: 480px) and (-webkit-device-pixel-ratio: 1)" href="../../../assets/icons/apple-touch-startup-image-320x460.png">
<link rel="apple-touch-startup-image" media="(device-width: 320px) and (device-height: 480px) and (-webkit-device-pixel-ratio: 2)" href="../../../assets/icons/apple-touch-startup-image-640x920.png">
<link rel="apple-touch-startup-image" media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)" href="../../../assets/icons/apple-touch-startup-image-640x1096.png">
<link rel="apple-touch-startup-image" media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)" href="../../../assets/icons/apple-touch-startup-image-750x1294.png">
<link rel="apple-touch-startup-image" media="(device-width: 414px) and (device-height: 736px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3)" href="../../../assets/icons/apple-touch-startup-image-1182x2208.png">
<link rel="apple-touch-startup-image" media="(device-width: 414px) and (device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3)" href="../../../assets/icons/apple-touch-startup-image-1242x2148.png">
<link rel="apple-touch-startup-image" media="(device-width: 768px) and (device-height: 1024px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 1)" href="../../../assets/icons/apple-touch-startup-image-748x1024.png">
<link rel="apple-touch-startup-image" media="(device-width: 768px) and (device-height: 1024px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 1)" href="../../../assets/icons/apple-touch-startup-image-768x1004.png">
<link rel="apple-touch-startup-image" media="(device-width: 768px) and (device-height: 1024px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2)" href="../../../assets/icons/apple-touch-startup-image-1496x2048.png">
<link rel="apple-touch-startup-image" media="(device-width: 768px) and (device-height: 1024px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2)" href="../../../assets/icons/apple-touch-startup-image-1536x2008.png">
</head>

<div class="container-fluid row" id="main-container" itemscope itemtype="https://schema.org/QuoteAction">

    <h1 class="col-12 quote-form-header" aria-label="Get a quote heading" itemprop="name">Get a Quote</h1>
    
    <div class="col-12" id="quote-info">
        <div class="col-12 col-lg-6 offset-lg-3 quote-description">
            <p itemprop="description">
                Fill out the form below to receive a quote on our Roll-Off Dumpster today! 
                <br>Choose between a 12 yard roll-off or one of our 16 yard roll-offs to take care of all of your waste needs. 
            </p>
            <a class="info-links" routerLink="/our-rates" itemprop="significantLink">View Our Rates</a>
        </div>
    </div>
    
    <div class="col-12" id="quote-container" itemscope itemtype="https://schema.org/CommunicateAction">
        <form class="quote-form container-fluid row" [formGroup]="quoteForm" (ngSubmit)="onSubmit()" aria-label="quote form." itemscope itemtype="https://schema.org/EntryPoint">

            <div class="col-12 col-sm-6" style="margin:0;">
                <label itemprop="contentType">First Name*</label>
                <input class="form-control" type="text" placeholder="e.g. John" formControlName="firstName" aria-label="Input name here." itemprop="actionPlatform" />
            </div>
            <div class="col-12 col-sm-6">
                <label itemprop="contentType">Last Name*</label>
                <input class="form-control" type="text" placeholder="e.g. Smith" formControlName="lastName" aria-label="Input name here." itemprop="actionPlatform" />
            </div>
            <div class="col-12 col-sm-6">
                <label itemprop="contentType">E-Mail*</label>
                <input class="form-control" type="text" placeholder="e.g. email@domain.com" formControlName="email" aria-label="Input e-mail address here." itemprop="actionPlatform" />
            </div>
            <div class="col-12 col-sm-6">
                <label itemprop="contentType">Phone Number*</label>
                <input class="form-control" type="text" placeholder="e.g. 123 456 7890" formControlName="phone" aria-label="Input phone number here." itemprop="actionPlatform" />
            </div>

            <div class="col-12 col-sm-6">
                <label itemprop="contentType">Delivery Address*</label>
                <input class="form-control" type="text" placeholder="e.g. 8166 Rollerd Dr, Findlay, Ohio" formControlName="address" aria-label="Input subject of message here." itemprop="actionPlatform" />
            </div>
            <div class="col-12 col-sm-6" style="text-align: center;">
                <label itemprop="contentType">Postal code*</label>
                <input class="form-control" type="text" placeholder="e.g. 45804" formControlName="zipcode" aria-label="Input subject of message here." itemprop="actionPlatform" />
            </div>
            <!-- <div class="col-12 col-sm-6 offset-sm-3" style="text-align: center;">
                <label itemprop="contentType">Requested Service*</label>
                <select class="form-control" formControlName="job" itemprop="actionPlatform">
                    <option disabled selected value=null> --Select-- </option>
                    <option *ngFor="let job of jobs" [value]="job.name" selected="selected">
                        {{ job.name }}
                    </option>
                </select>
            </div> -->


            <div class="col-12 row" style="padding:0;margin:0;">
                <div class="col-6" style="text-align: center;">
                    <label itemprop="contentType">Dumpster Size*</label>
                    <select class="form-control" formControlName="unitSize" itemprop="actionPlatform">
                        <option disabled selected value=null> --Select-- </option>
                        <option  *ngFor="let size of sizes" [value]="size.name" selected="selected">
                            {{ size.name }}
                        </option>
                    </select>
                </div>
                <div class="col-6" style="text-align: center;">
                    <label itemprop="contentType">Waste Material*</label>
                    <select class="form-control" formControlName="wasteMaterial">
                        <option disabled selected value=null> --Select-- </option>
                        <option  *ngFor="let waste of wastes" [value]="waste.name" selected="selected">
                            {{ waste.name }}
                        </option>
                    </select>
                </div>
            </div> 
            <div class="col-12 row" style="padding:0;margin:0;">
                <div class="col-6" style="text-align: center;">
                    <label itemprop="contentType">Start date*</label>
                    <input class="form-control" type="date" placeholder="e.g. yyyy-mm-dd" formControlName="startDate" aria-label="Input start date here." itemprop="actionPlatform" />
                </div>
                <div class="col-6" style="text-align: center;">
                    <label itemprop="contentType">Rental Period*</label>
                    <select class="form-control" formControlName="estimatedLength" itemprop="actionPlatform">
                        <option disabled selected value=null> --Select-- </option>
                        <option *ngFor="let month of months" [value]="month.name" selected="selected">
                            {{ month.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-6 offset-3" style="text-align: center;">
                <button class="btn btn-primary" type="submit" [disabled]="!quoteForm.valid" aria-label="Submit button." itemprop="httpMethod">Submit</button>
            </div>
            <div class="col-12" id="validateForm" *ngIf="!quoteForm.valid" itemprop="potentialAction">Please complete all fields in the form.</div>
            <div class="col-12" id="validateForm" *ngIf="quoteForm.valid" itemprop="potentialAction">You may submit the form.</div>
        </form>
    </div>
    <div class="col-12 contact-link">
        <a class="link" routerLink="/contact-us" itemprop="significantLink">Have questions? Click here!</a>
    </div>
    
</div>
