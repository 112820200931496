<!doctype html>
<html lang="en">
<head>
<meta name="description" content="Check Out Our Rates! RollAwayz Dumpster Rental : RollAwayz Dumpster Rental in the Lima, Findlay, Van Wert, Delphos, Fort Wayne, Dayton Ohio and surrounding areas. We are your local moving and mobile self storage container solution! Our portable self storage units make moving and storing easier.">
<meta property="og:description" content="Check Out Our Rates! RollAwayz Dumpster Rental : RollAwayz Dumpster Rental in the Northwestern Ohio area. We are your local moving and mobile self storage container solution! Our portable self storage units make moving and storing easier.">
<meta name="twitter:description" content="Check Out Our Rates! RollAwayz Dumpster Rental : RollAwayz Dumpster Rental in the Northwestern Ohio area. We are your local moving and mobile self storage container solution! Our portable self storage units make moving and storing easier.">
<meta property="og:type" content="website">
<meta property="og:site_name" content="RollAwayz Dumpster Rental">
<meta name="twitter:card" content="summary">
<meta property="og:title" content="Our RollAwayz Dumpster Rental Rates in Lima, Ohio | RollAwayz Dumpster Rental">
<meta name="twitter:title" content="RollAwayz Dumpster Rental Rates in Lima, Ohio and the surrounding areas | Compare us to Bin-There-Dump-That® and Republic for Roll Off Dumpster Rentals | RollAwayz Dumpster Rental">
<meta name="viewport"content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
<meta name="mobile-web-app-capable" content="yes">
<meta name="apple-mobile-web-app-capable" content="yes">
<meta name="apple-mobile-web-app-status-bar-style" content="black-translucent">
<meta property="og:locale" content="en_US" />
<!-- Chrome, Firefox OS and Opera -->
<meta name="theme-color" content="#e43938">
<!-- Windows Phone -->
<meta name="msapplication-navbutton-color" content="#e43938">
<!-- iOS Safari -->
<meta name="apple-mobile-web-app-status-bar-style" content="#e43938">
<!-- Global site tag (gtag.js) - Google Analytics -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-DX8SHPVZ7M"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-DX8SHPVZ7M');
</script>
<link rel="canonical" href="https://www.rollawayzdumpsters.com/our-rates" />
<link rel="yandex-tableau-widget" href="../../../assets/icons/yandex-browser-manifest.json">
<link rel="apple-touch-icon" sizes="57x57" href="../../../assets/icons/apple-touch-icon-57x57.png">
<link rel="apple-touch-icon" sizes="60x60" href="../../../assets/icons/apple-touch-icon-60x60.png">
<link rel="apple-touch-icon" sizes="72x72" href="../../../assets/icons/apple-touch-icon-72x72.png">
<link rel="apple-touch-icon" sizes="76x76" href="../../../assets/icons/apple-touch-icon-76x76.png">
<link rel="apple-touch-icon" sizes="114x114" href="../../../assets/icons/apple-touch-icon-114x114.png">
<link rel="apple-touch-icon" sizes="120x120" href="../../../assets/icons/apple-touch-icon-120x120.png">
<link rel="apple-touch-icon" sizes="144x144" href="../../../assets/icons/apple-touch-icon-144x144.png">
<link rel="apple-touch-icon" sizes="152x152" href="../../../assets/icons/apple-touch-icon-152x152.png">
<link rel="apple-touch-icon" sizes="180x180" href="../../../assets/icons/apple-touch-icon-180x180.png">
<link rel="apple-touch-icon" sizes="1024x1024" href="../../../assets/icons/apple-touch-icon-1024x1024.png">
<link rel="icon" type="image/png" sizes="192x192" href="../../../assets/icons/android-chrome-192x192.png">
<link rel="icon" type="image/png" sizes="32x32" href="../../../assets/icons/favicon-32x32.png">
<link rel="icon" type="image/png" sizes="16x16" href="../../../assets/icons/favicon-16x16.png">
<link rel="apple-touch-startup-image" media="(device-width: 320px) and (device-height: 480px) and (-webkit-device-pixel-ratio: 1)" href="../../../assets/icons/apple-touch-startup-image-320x460.png">
<link rel="apple-touch-startup-image" media="(device-width: 320px) and (device-height: 480px) and (-webkit-device-pixel-ratio: 2)" href="../../../assets/icons/apple-touch-startup-image-640x920.png">
<link rel="apple-touch-startup-image" media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)" href="../../../assets/icons/apple-touch-startup-image-640x1096.png">
<link rel="apple-touch-startup-image" media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)" href="../../../assets/icons/apple-touch-startup-image-750x1294.png">
<link rel="apple-touch-startup-image" media="(device-width: 414px) and (device-height: 736px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3)" href="../../../assets/icons/apple-touch-startup-image-1182x2208.png">
<link rel="apple-touch-startup-image" media="(device-width: 414px) and (device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3)" href="../../../assets/icons/apple-touch-startup-image-1242x2148.png">
<link rel="apple-touch-startup-image" media="(device-width: 768px) and (device-height: 1024px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 1)" href="../../../assets/icons/apple-touch-startup-image-748x1024.png">
<link rel="apple-touch-startup-image" media="(device-width: 768px) and (device-height: 1024px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 1)" href="../../../assets/icons/apple-touch-startup-image-768x1004.png">
<link rel="apple-touch-startup-image" media="(device-width: 768px) and (device-height: 1024px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2)" href="../../../assets/icons/apple-touch-startup-image-1496x2048.png">
<link rel="apple-touch-startup-image" media="(device-width: 768px) and (device-height: 1024px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2)" href="../../../assets/icons/apple-touch-startup-image-1536x2008.png">
</head>

<div class="container-fluid" id="main-container" itemscope itemtype="http://schema.org/DumpsterRentals">
    <div id="white-background" itemscope itemtype="http://schema.org/Product">
        <div class="col-12 page-title">
            <h1 itemprop="name">Our Rates</h1>
        </div>
        <div class="col-10 offset-1 col-lg-6 offset-lg-3 page-description">
            <p itemprop="description">
                At RollAwayz, you will find some of the best rates for roll-offs. <br>We offer 12 and 16 yard containers that are
                sturdy, appealing, convenient, and more!<br> Our dumpsters are made for the customer to easily be able to
                maneuver around and throw junk into without hassle.
                <br>We do not like hidden fees, and having unknown costs with delivery and pickup is not a good experience. That is why we are upfront and honest 
                with you about the cost before the dumpster is delivered.
                <br><br>Servicing roll-off dumpster rentals throughout Ohio, we strive to provide the best customer experience
                possible. Start your booking online today!
        
                <br><br>The prices shown below are a starting price within a 20 mile radius of our drop-spot.
                <b><u><br><br>Scenarios that may affect cost are as follows: </u><br>
        
                <li>Distance & Location</li>
                <li>Materials</li>
                <li>Weight</li>
                </b>
            </p>
        </div>

        <div id="fees-background" style="background-color: #606060;">
                <div class="fees-container" itemscope itemtype="http://schema.org/Product">
                    
                    <table class="col-12 fees" style="width:100%; border:solid 1px rgb(208,17,43);">
                        <tr class="col-12" style="border-bottom: 1px solid rgb(57, 61, 63)">
                            <th></th>
                            <th><strong>Weekly Rent</strong></th>
                            <th><strong>Monthly Rent</strong></th>
                        </tr>
                        <tr style="border-bottom: 1px solid rgb(57, 61, 63)">
                            <th id="container-type" itemprop="model">12 Yard Roll-Off</th>
                            <td itemprop="price"><i><small>Starting at: </small></i><b>$255</b></td>
                            <td><a routerLink="/contact-us" itemprop="significantLink">Contact Us</a></td>
                        </tr>
                        <tr style="border-bottom: 1px solid rgb(57, 61, 63)">
                            <th id="container-type" itemprop="model">16 Yard Roll-Off</th>
                            <td itemprop="price"><i><small>Starting at: </small></i><b>$285</b></td>
                            <td><a routerLink="/contact-us" itemprop="significantLink">Contact Us</a></td>
                        </tr>
                    </table>
                </div>
    
                <div class="fees-container" style="margin-top: 40px;" itemscope itemtype="http://schema.org/Product">
                    <table class="col-12 fees" style="width:100%; border:solid 1px rgb(208,17,43); border-radius: 10px;">
                        <tr class="col-12" style="border-bottom: 1px solid rgb(57, 61, 63)" itemscope itemtype="https://schema.org/Organization">
                            <th></th>
                            <th itemprop="areaServed"><strong>Under 20 miles</strong></th>
                            <th itemprop="areaServed"><strong>Over 20 miles</strong></th>
                            <th itemprop="areaServed"><strong>Over 100 miles</strong></th>
                        </tr>
                        <tr style="border-bottom: 1px solid rgb(57, 61, 63)">
                            <th id="container-type">Delivery</th>
                            <td itemprop="price">Included</td>
                            <td itemprop="price">$3/mile over 20 miles</td>
                            <td><a routerLink="/contact-us" itemprop="significantLink">Contact Us</a></td>
                        </tr>
                        <tr>
                            <th id="container-type">Moving Locations</th>
                            <td itemprop="price">Included</td>
                            <td itemprop="price">$3/mile over 20 miles</td>
                            <td><a routerLink="/contact-us" itemprop="significantLink">Contact Us</a></td>
                        </tr>
                    </table>
                </div>
            </div>

        <!-- Payment methods -->
        <h2 itemscope itemtype="https://schema.org/PaymentMethod">Payment Methods</h2>
        <div class="col-10 offset-1 col-lg-6 offset-lg-3" id="page-description">
            <div class="col-12 row" style="padding:0;">
                <div class="col-6 icons"><i class="far fa-credit-card" itemprop="image"></i></div>
                <div class="col-6 icons"><i class="fas fa-money-check-alt" itemprop="image"></i></div>
            </div>
            <p itemprop="description">We make payment for your RollAwayz dumpster easy. Payments can be made in the form of Cash, Check, or we can even accept Venmo payments, eliminating the trouble. <br>
            We are currently in the process of being able to accept debit and credit cards, as well as setting up automatic payments for extended or repeating dumpster rentals.</p>
        </div>
    </div>
</div>

