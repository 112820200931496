import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'x-api-key': 'KCQV0U5dSa47Z2E7m0uH08KVwsZ7fbds9ODeQCG4'
  })
};

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  emailUrl = 'https://kv3invdco5.execute-api.us-east-1.amazonaws.com/prod/send-email';

  constructor(private http: HttpClient) { }

  async sendEmail(templateName, sendTo, data) {
    let body = {templateName, sendTo, data};
    try {
      await this.http.post(this.emailUrl, body, httpOptions).toPromise();
    } catch (e) {
      throw e;
    }
  }

}
