<!doctype html>
<html lang="en">
<head>
<meta name="viewport"content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
<meta name="description" content="Book online! RollAwayz Dumpster Rental : RollAwayz Dumpster Rental servicing Lima, Findlay, Van Wert, Delphos, Fort Wayne, Dayton and the surrounding areas in Ohio. We are your local moving and mobile self storage container solution! Our portable self storage units make moving and storing easier.">
<meta property="og:description" content="Book online! RollAwayz Dumpster Rental : RollAwayz Dumpster Rental servicing Lima, Findlay, Van Wert, Delphos, Fort Wayne, Dayton and the surrounding areas in Ohio. We are your local moving and mobile self storage container solution! Our portable self storage units make moving and storing easier.">
<meta name="twitter:description" content="Book online! RollAwayz Dumpster Rental : RollAwayz Dumpster Rental servicing Lima, Findlay, Van Wert, Delphos, Fort Wayne, Dayton and the surrounding areas in Ohio. We are your local moving and mobile self storage unit solution! Our portable self storage units make moving and storing easier.">
<meta property="og:type" content="website">
<meta property="og:site_name" content="RollAwayz Dumpster Rental">
<meta name="twitter:card" content="summary">
<meta property="og:title" content="RollAwayz Dumpster Rental in Lima, Ohio | RollAwayz Dumpster Rental">
<meta property="og:image" content="https://www.rollawayzdumpsters.com/assets/bg/dumpster_delivered_ottawa_ohio.jpg">
<meta name="twitter:title" content="RollAwayz Dumpster Rental in Lima, Ohio | Compare us to Bin-There-Dump-That® and Republic for Roll Off Dumpster Rentals | RollAwayz Dumpster Rental">
<meta name="mobile-web-app-capable" content="yes">
<meta name="apple-mobile-web-app-capable" content="yes">
<meta name="apple-mobile-web-app-status-bar-style" content="black-translucent">
<meta property="og:locale" content="en_US" />
<meta name="msvalidate.01" content="F249D1CC7B4EA3DA36E45B9A2C69378D" />
<!-- Chrome, Firefox OS and Opera -->
<meta name="theme-color" content="#e43938">
<!-- Windows Phone -->
<meta name="msapplication-navbutton-color" content="#e43938">
<!-- iOS Safari -->
<meta name="apple-mobile-web-app-status-bar-style" content="#e43938">
<!-- Global site tag (gtag.js) - Google Analytics -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-DX8SHPVZ7M"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-DX8SHPVZ7M');
</script>
<link rel="canonical" href="https://www.rollawayzdumpsters.com/" />
<link rel="yandex-tableau-widget" href="../../../assets/icons/yandex-browser-manifest.json">
<link rel="apple-touch-icon" sizes="57x57" href="../../../assets/icons/apple-touch-icon-57x57.png">
<link rel="apple-touch-icon" sizes="60x60" href="../../../assets/icons/apple-touch-icon-60x60.png">
<link rel="apple-touch-icon" sizes="72x72" href="../../../assets/icons/apple-touch-icon-72x72.png">
<link rel="apple-touch-icon" sizes="76x76" href="../../../assets/icons/apple-touch-icon-76x76.png">
<link rel="apple-touch-icon" sizes="114x114" href="../../../assets/icons/apple-touch-icon-114x114.png">
<link rel="apple-touch-icon" sizes="120x120" href="../../../assets/icons/apple-touch-icon-120x120.png">
<link rel="apple-touch-icon" sizes="144x144" href="../../../assets/icons/apple-touch-icon-144x144.png">
<link rel="apple-touch-icon" sizes="152x152" href="../../../assets/icons/apple-touch-icon-152x152.png">
<link rel="apple-touch-icon" sizes="180x180" href="../../../assets/icons/apple-touch-icon-180x180.png">
<link rel="apple-touch-icon" sizes="1024x1024" href="../../../assets/icons/apple-touch-icon-1024x1024.png">
<link rel="icon" type="image/png" sizes="192x192" href="../../../assets/icons/android-chrome-192x192.png">
<link rel="icon" type="image/png" sizes="32x32" href="../../../assets/icons/favicon-32x32.png">
<link rel="icon" type="image/png" sizes="16x16" href="../../../assets/icons/favicon-16x16.png">
<link rel="apple-touch-startup-image" media="(device-width: 320px) and (device-height: 480px) and (-webkit-device-pixel-ratio: 1)" href="../../../assets/icons/apple-touch-startup-image-320x460.png">
<link rel="apple-touch-startup-image" media="(device-width: 320px) and (device-height: 480px) and (-webkit-device-pixel-ratio: 2)" href="../../../assets/icons/apple-touch-startup-image-640x920.png">
<link rel="apple-touch-startup-image" media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)" href="../../../assets/icons/apple-touch-startup-image-640x1096.png">
<link rel="apple-touch-startup-image" media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)" href="../../../assets/icons/apple-touch-startup-image-750x1294.png">
<link rel="apple-touch-startup-image" media="(device-width: 414px) and (device-height: 736px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3)" href="../../../assets/icons/apple-touch-startup-image-1182x2208.png">
<link rel="apple-touch-startup-image" media="(device-width: 414px) and (device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3)" href="../../../assets/icons/apple-touch-startup-image-1242x2148.png">
<link rel="apple-touch-startup-image" media="(device-width: 768px) and (device-height: 1024px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 1)" href="../../../assets/icons/apple-touch-startup-image-748x1024.png">
<link rel="apple-touch-startup-image" media="(device-width: 768px) and (device-height: 1024px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 1)" href="../../../assets/icons/apple-touch-startup-image-768x1004.png">
<link rel="apple-touch-startup-image" media="(device-width: 768px) and (device-height: 1024px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2)" href="../../../assets/icons/apple-touch-startup-image-1496x2048.png">
<link rel="apple-touch-startup-image" media="(device-width: 768px) and (device-height: 1024px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2)" href="../../../assets/icons/apple-touch-startup-image-1536x2008.png">
</head>
<div class="container-fluid main" itemscope itemtype="https://schema.org/DumpsterRentals">
      <ngb-carousel *ngIf="images">
         <ng-template ngbSlide itemprop="potentialAction">
            <div class="picsum-img-wrapper">
               <img [src]="images[0]" id="carousel-image" itemprop="image" src="../../../assets/bg/dumpster_delivered_ottawa_ohio.jpg" alt="RollAwayz Dumpster Rental being unloaded.">
            </div>
            <div class="carousel-caption">
               <h1 itemprop="slogan"><strong>Request Your Roll-Off Dumpster Quote Today</strong></h1>
               <br />
               <span itemprop="identifier">
                  <a class="info-links" routerLink="/our-rates" itemprop="url">View Our Rates</a>
                  <a class="info-links" routerLink="/quotes" itemprop="url">Get A Quote</a>
               </span>
            </div>
         </ng-template>

         <ng-template ngbSlide itemprop="potentialAction">
            <div class="picsum-img-wrapper">
               <img [src]="images[1]" id="carousel-image" itemprop="image" src="../../../assets/bg/dumpster_fleet_12yards_16yards.jpg" alt="RollAwayz Dumpster being unloaded.">
            </div>
            <div class="carousel-caption">
               <h1 itemprop="slogan"><strong>Request Your Roll-Off Dumpster Quote Today</strong></h1>
               <br />
               <span itemprop="identifier">
                  <a class="info-links" routerLink="/our-rates" itemprop="url">View Our Rates</a>
                  <a class="info-links" routerLink="/quotes" itemprop="url">Get a Quote</a>
               </span>
            </div>
         </ng-template>

      </ngb-carousel>
 
     <div style="background-color: rgb(255,255,255);" itemprop="mainEntityOfPage">
         <h1 class="about-header" itemprop="name">Why Choose RollAwayz For Dumpster Rentals?</h1>
     </div>
     <div class="card about" itemprop="description">
            <div class="col-md-12 about-description" itemprop="description">
               <div id="pscontainer-top" itemprop="description">
                  <p dir="ltr" style="padding-top: 2%;" itemprop="disambiguatingDescription"><strong><span style="font-size: 2em; color: Tomato;"><i class="fas fa-rocket"></i></span><br><b>SPEED</b></strong></p>
                  <p dir="ltr" itemprop="disambiguatingDescription">We have a fleet of drivers ready to deliver roll-off dumpsters. We can usually accomodate next-day service for you when calling before 1pm.</p>
                  <p dir="ltr" itemprop="additionalType"><strong><span style="font-size: 2em; color: Tomato;"><i class="fas fa-certificate"></i></span><br><b> QUALITY</b></strong></p>
                  <p dir="ltr" itemprop="disambiguatingDescription">Each Dumpster we deliver is constructed of high quality steel that is free of rust and holes. They are sustainable under every weather condition, and will not damage your property.
                      We understand that looks are important to some, and that worry will be gone with a Rollawayz dumpster. Unlike our competitors, all of our dumpsters have <b>four</b> roller wheels that ensure the dumpster never scrapes a driveway.</p>
                  <p dir="ltr" itemprop="additionalType"><strong><span style="font-size: 2em; color: Tomato;"><i class="fas fa-star"></i></span><br><b> EXPERTISE</b></strong></p>
                  <p dir="ltr" itemprop="disambiguatingDescription">We've worked on many projects in the Northwestern Ohio area. From hard to reach areas or crowded areas like Downtown, we've done it all. Our drivers are highly experienced and will always take proper precaution when delivering your roll-off.</p>
               </div>
               <div class="col-12" id="box-image" itemprop="image">
                  <img alt="RollAwayz Dumpster Rental and Container Image" itemprop="photo" title="RollAwayz Dumpster Rental and Container Image" src="../../../assets/bg/rollawayz_rolloff_delivered.jpg" loading="lazy"/>
               </div>
               <div class="row" style="margin: 0;" itemprop="description">
                  <div class="col-12" id="pscontainer-bottom" itemprop="description">
                     <p dir="ltr" id="line-margin" itemprop="additionalType"><strong><span style="font-size: 2em; color: Tomato;"><i class="fas fa-lock"></i></span><br><b>  SECURITY</b></strong></p>
                     <p dir="ltr" id="line-margin" itemprop="disambiguatingDescription">We never sell your information, and if information is needed to be stored - it is with our Level 1 PCI-Compliant storage provider. No one at our Company has access to your data apart from your name and date of rental.</p>
                     <p dir="ltr" id="line-margin" itemprop="additionalType"><strong><span style="font-size: 2em; color: Tomato;"><i class="fas fa-handshake"></i></span><br><b>  RELIABILITY</b></strong></p>
                     <p dir="ltr" id="line-margin" itemprop="disambiguatingDescription">At Rollawayz, we do what we say. You will never be left wondering when or if your driver will show up. If you ever have any issues, there is always a representative just a phone call away.</p>
                     <p dir="ltr" id="line-margin" itemprop="additionalType"><strong><span style="font-size: 2em; color: Tomato;"><i class="fas fa-piggy-bank"></i></span><br><b>  PRICE</b></strong></p>
                     <p dir="ltr" id="line-margin" itemprop="disambiguatingDescription">We are driven to provide high quality, cost effective solutions. Our dumpster rental prices vary based on the weight of the material you are throwing away, individual city taxes, pick-up and drop-off distance, and distance from the landfill. Call or Text (419) 615-5886 for a free quote in uder 30 seconds.</p>
                     <p dir="ltr" id="line-margin" itemprop="additionalType"><strong><span style="font-size: 2em; color: Tomato;"><i class="fas fa-universal-access"></i></span><br><b>  ACCESS</b></strong></p>
                     <p dir="ltr" id="line-margin" itemprop="disambiguatingDescription" style="padding-bottom: 2%;">Having one of our roll off dumpsters means that you can have access to it at any time! We seek to provide you with the exact spot you request to have the dumpster placed.
                        <br><br><b><i>Servicing the Lima, Findlay, Ottawa, and surrounding Northwest Ohio areas - RollAwayz serves to provide you the best Roll-Off Dumpsters for every project.</i></b></p>
                  </div>
               </div>
            </div>
         </div>

   <div style="background-color: rgb(255,255,255)">

     <div class="col-12 icon-info" itemprop="image">
        <div class="col-12 col-lg-10 offset-lg-1 row icons" itemprop="image">
            <div class="col-2 offset-1"><i class="fas fa-dumpster" itemprop="photo"></i></div>
            <div class="col-2"><i class="fas fa-truck-loading" itemprop="photo"></i></div>
            <div class="col-2"><i class="fas fa-hand-holding-usd" itemprop="photo"></i></div>
            <div class="col-2"><i class="far fa-lightbulb" itemprop="photo"></i></div>
            <div class="col-2"><i class="fas fa-people-carry" itemprop="photo"></i></div>
        </div>
        <div class="col-12 col-lg-10 offset-lg-1 row icon-desc">
            <div class="col-2 offset-1" itemprop="slogan">High-Quality<br>Dumpsters</div>
            <div class="col-2" itemprop="slogan">Express<br>Delivery</div>
            <div class="col-2" itemprop="slogan">Affordable<br>Pricing</div>
            <div class="col-2" itemprop="slogan">Innovative<br>Service</div>
            <div class="col-2" itemprop="slogan">Individualized<br>Care</div>
        </div>
     </div>
    
   </div>
   <div class="col-sm-12" itemprop="hasMap" #mapContainer id="map" style="margin: 0;"></div>
 
 </div> 