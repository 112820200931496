import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EmailService } from 'src/app/services/email/email.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.scss']
})

export class QuotesComponent implements OnInit, AfterViewInit {

  public quoteForm: FormGroup;

  months = [
    {name: '7 Days'},
    {name: '10 Days'},
    {name: 'Other'}
  ];

  wastes =[
    {name: 'Construction Materials'},
    {name: 'Trash'},
    {name: 'Mixed (Trash & Construction)'},
    {name: 'Other'}
  ];

  sizes =[
    {name: '12 Yards'},
    {name: '16 Yards'}
  ];

  constructor(private emailService: EmailService, private router: Router) { }

  ngOnInit() {
    this.createForm();
  }

  ngAfterViewInit() {
    window.scroll(0,0);
  }

  private createForm(): void {
    this.quoteForm = new FormGroup({
      firstName: new FormControl(null, [Validators.required]),
      lastName: new FormControl(null, [Validators.required]),
      phone: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      zipcode: new FormControl(null, [Validators.required]),
      startDate: new FormControl(null, [Validators.required]),
      estimatedLength: new FormControl(null, [Validators.required]),
      address: new FormControl(null, [Validators.required]),
      unitSize: new FormControl(null, [Validators.required]),
      wasteMaterial: new FormControl(null, [Validators.required])
    });
  }

  public onSubmit(): void {
    if (this.quoteForm.valid) {
      this.emailService.sendEmail('rollawayz_quote', 'djvogt94@gmail.com', this.quoteForm.value);
      Swal.fire("Thank You", "Your request has been sent successfully.", "success");
      this.quoteForm.reset();
      window.scroll(0,0);
    } else {
      console.log('invalid form');
    }
  }
}
