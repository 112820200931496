import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EmailService } from 'src/app/services/email/email.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

declare const google: any;

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  public contactUsForm: FormGroup;

  @ViewChild('mapContainer', {static: false}) gmap: ElementRef;

  map: google.maps.Map;
  lat = 40.728070;
  lng = -84.076690;

  coordinates = new google.maps.LatLng(this.lat, this.lng);

  mapOptions: google.maps.MapOptions = {
    center: this.coordinates,
    zoom: 7,
    disableDefaultUI: true,
    styles: [
    {
      "featureType": "all",
      "elementType": "all",
      "stylers": [
          {
              "saturation": -100
          },
          {
              "gamma": 0.5
          }
      ]
    },
    {
      "featureType": "administrative.locality",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "visibility": "on"
          },
          {
              "color": "#0c0a0a"
          }
      ]
    },
    {
      "featureType": "administrative.locality",
      "elementType": "labels.text.fill",
      "stylers": [
          {
              "visibility": "on"
          },
          {
              "weight": 0.01
          },
          {
              "color": "#0b0909"
          }
      ]
  },
  {
      "featureType": "administrative.locality",
      "elementType": "labels.text.stroke",
      "stylers": [
          {
              "weight": 5.09
          },
          {
              "gamma": 0.94
          },
          {
              "hue": "#ff3900"
          }
      ]
  },
  {
      "featureType": "administrative.locality",
      "elementType": "labels.icon",
      "stylers": [
          {
              "visibility": "on"
          }
      ]
  },
  {
      "featureType": "road",
      "elementType": "geometry.stroke",
      "stylers": [
          {
              "visibility": "on"
          },
          {
              "color": "#fb1515"
          },
          {
              "saturation": 0
          },
          {
              "lightness": 43
          },
          {
              "gamma": 0.92
          }
      ]
  },
  {
      "featureType": "road",
      "elementType": "labels.text.fill",
      "stylers": [
          {
              "visibility": "on"
          },
          {
              "hue": "#ff0000"
          }
      ]
  },
  {
      "featureType": "road",
      "elementType": "labels.text.stroke",
      "stylers": [
          {
              "visibility": "on"
          },
          {
              "hue": "#ff0000"
          }
      ]
  },
  {
      "featureType": "road",
      "elementType": "labels.icon",
      "stylers": [
          {
              "visibility": "off"
          },
          {
              "color": "#ff0000"
          }
      ]
  },
  {
      "featureType": "road.highway.controlled_access",
      "elementType": "labels.text.stroke",
      "stylers": [
          {
              "visibility": "on"
          },
          {
              "hue": "#ff0000"
          }
      ]
  },
  {
      "featureType": "road.local",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "color": "#ff0000"
          }
      ]
  },
  {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
          {
              "visibility": "on"
          },
          {
              "color": "#ff0000"
          }
      ]
  },
  {
      "featureType": "road.local",
      "elementType": "labels.text.stroke",
      "stylers": [
          {
              "visibility": "simplified"
          },
          {
              "color": "#ff0000"
          }
      ]
  },
  {
      "featureType": "road.local",
      "elementType": "labels.icon",
      "stylers": [
          {
              "visibility": "on"
          },
          {
              "color": "#ff0000"
          }
      ]
  },
  {
      "featureType": "transit.line",
      "elementType": "labels.text.fill",
      "stylers": [
          {
              "color": "#ff0000"
          }
      ]
  },
  {
      "featureType": "transit.line",
      "elementType": "labels.text.stroke",
      "stylers": [
          {
              "hue": "#ff0000"
          }
      ]
  },
  {
      "featureType": "transit.line",
      "elementType": "labels.icon",
      "stylers": [
          {
              "hue": "#ff0000"
          }
      ]
  }]};

  // Initializes all of the above google maps objects
  mapInitializer() {
    this.map = new google.maps.Map(document.getElementById('map'), 
    this.mapOptions);
    // this.marker.setMap(this.map);
    // this.cityCircle.setMap(this.map);
   

  const marker = new google.maps.Marker({
    position: this.coordinates,
    map: this.map
  });

  const cityCircle = new google.maps.Circle({
    strokeColor: '#393d3f',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#393d3f',
    fillOpacity: 0.35,
    map: this.map,
    center: { lat: this.lat, lng: this.lng },
    radius: 125000
  });
  }
  constructor(private emailService: EmailService) { }

  ngOnInit() {
    this.createForm();
  }

  ngAfterViewInit() {
    window.scroll(0,0);
    this.mapInitializer();
  }



  private createForm(): void {
    this.contactUsForm = new FormGroup({
    firstName: new FormControl(null, [Validators.required]),
    lastName: new FormControl(null, [Validators.required]),
    phone: new FormControl(null, [Validators.required]),
    email: new FormControl(null, [Validators.required, Validators.email]),
    subject: new FormControl(null, [Validators.required]),
    message: new FormControl(null, [Validators.required])
    });
  }
  
  public onSubmit(): void {
    if (this.contactUsForm.valid) {
        this.emailService.sendEmail('rollawayz_contact', 'rent@rollawayz.com', this.contactUsForm.value);
        Swal.fire("Thank You", "Your request has been sent successfully.", "success");
        this.contactUsForm.reset();
        window.scroll(0,0);
    } else {
        console.log('invalid form');
    }
  }

}
