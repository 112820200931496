<!doctype html>
<html lang="en">
  <head>
<meta name="description" content="Contact us with any questions about our available roll-off dumpster rental options. We service the Northwestern Ohio area within a 30-mile radius. ">
<meta property="og:description" content="Contact us with any questions about our rolloff trash containers. We service the Northwestern Ohio area within a 30-mile radius. ">
<meta name="twitter:description" content="Contact us with any questions about servicing you with a portable dumpster. We service the Northwestern Ohio area within a 30-mile radius. ">
<meta property="og:type" content="website">
<meta property="og:site_name" content="RollAwayz Dumpster Rental ">
<meta property="og:title" content="Contact RollAwayz Dumpster Rental in Lima, Ottawa, and Findlay Ohio | RollAwayz Dumpster Rental">
<meta name="twitter:title" content="Contact Us | RollAwayz Dumpster Rental near Ottawa, Ohio | RollAwayz Dumpster Rental">
<meta name="viewport"content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
<meta name="mobile-web-app-capable" content="yes">
<meta name="apple-mobile-web-app-capable" content="yes">
<meta name="apple-mobile-web-app-status-bar-style" content="black-translucent">
<meta property="og:locale" content="en_US" />
<!-- Chrome, Firefox OS and Opera -->
<meta name="theme-color" content="#e43938">
<!-- Windows Phone -->
<meta name="msapplication-navbutton-color" content="#e43938">
<!-- iOS Safari -->
<meta name="apple-mobile-web-app-status-bar-style" content="#e43938">
<!-- Global site tag (gtag.js) - Google Analytics -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-DX8SHPVZ7M"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-DX8SHPVZ7M');
</script>
<link rel="canonical" href="https://www.rollawayzdumpsters.com/contact-us" />
<link rel="yandex-tableau-widget" href="../../../assets/icons/yandex-browser-manifest.json">
<link rel="apple-touch-icon" sizes="57x57" href="../../../assets/icons/apple-touch-icon-57x57.png">
<link rel="apple-touch-icon" sizes="60x60" href="../../../assets/icons/apple-touch-icon-60x60.png">
<link rel="apple-touch-icon" sizes="72x72" href="../../../assets/icons/apple-touch-icon-72x72.png">
<link rel="apple-touch-icon" sizes="76x76" href="../../../assets/icons/apple-touch-icon-76x76.png">
<link rel="apple-touch-icon" sizes="114x114" href="../../../assets/icons/apple-touch-icon-114x114.png">
<link rel="apple-touch-icon" sizes="120x120" href="../../../assets/icons/apple-touch-icon-120x120.png">
<link rel="apple-touch-icon" sizes="144x144" href="../../../assets/icons/apple-touch-icon-144x144.png">
<link rel="apple-touch-icon" sizes="152x152" href="../../../assets/icons/apple-touch-icon-152x152.png">
<link rel="apple-touch-icon" sizes="180x180" href="../../../assets/icons/apple-touch-icon-180x180.png">
<link rel="apple-touch-icon" sizes="1024x1024" href="../../../assets/icons/apple-touch-icon-1024x1024.png">
<link rel="icon" type="image/png" sizes="192x192" href="../../../assets/icons/android-chrome-192x192.png">
<link rel="icon" type="image/png" sizes="32x32" href="../../../assets/icons/favicon-32x32.png">
<link rel="icon" type="image/png" sizes="16x16" href="../../../assets/icons/favicon-16x16.png">
<link rel="apple-touch-startup-image" media="(device-width: 320px) and (device-height: 480px) and (-webkit-device-pixel-ratio: 1)" href="../../../assets/icons/apple-touch-startup-image-320x460.png">
<link rel="apple-touch-startup-image" media="(device-width: 320px) and (device-height: 480px) and (-webkit-device-pixel-ratio: 2)" href="../../../assets/icons/apple-touch-startup-image-640x920.png">
<link rel="apple-touch-startup-image" media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)" href="../../../assets/icons/apple-touch-startup-image-640x1096.png">
<link rel="apple-touch-startup-image" media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)" href="../../../assets/icons/apple-touch-startup-image-750x1294.png">
<link rel="apple-touch-startup-image" media="(device-width: 414px) and (device-height: 736px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3)" href="../../../assets/icons/apple-touch-startup-image-1182x2208.png">
<link rel="apple-touch-startup-image" media="(device-width: 414px) and (device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3)" href="../../../assets/icons/apple-touch-startup-image-1242x2148.png">
<link rel="apple-touch-startup-image" media="(device-width: 768px) and (device-height: 1024px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 1)" href="../../../assets/icons/apple-touch-startup-image-748x1024.png">
<link rel="apple-touch-startup-image" media="(device-width: 768px) and (device-height: 1024px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 1)" href="../../../assets/icons/apple-touch-startup-image-768x1004.png">
<link rel="apple-touch-startup-image" media="(device-width: 768px) and (device-height: 1024px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2)" href="../../../assets/icons/apple-touch-startup-image-1496x2048.png">
<link rel="apple-touch-startup-image" media="(device-width: 768px) and (device-height: 1024px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2)" href="../../../assets/icons/apple-touch-startup-image-1536x2008.png">
</head>

<div class="container-fluid row" id="main-container" itemscope itemtype="https://schema.org/ContactPage">
  <div class="col-12" id="contact-info" >
    <h1 class="col-12 contact-form-header" aria-label="Contact us heading" itemprop="headline">Contact Us</h1>
    <div class="col-12 contact-link">
        <i class="fas fa-phone-alt"></i><a href="tel:1-419-615-5886" itemprop="significantLink">   (419 615-5886</a>
    </div>
    
    <div class="col-12 contact-link">
      <i class="far fa-envelope" itemprop="image"></i>
      <a href="mailto:rent@rollawayz.com" itemprop="significantLink">   rent@rollawayz.com</a>
    </div>
    <!-- <div class="col-12 contact-link">
      <i class="far fa-credit-card" itemprop="image"></i>
      <a href="" itemprop="significantLink">   Order Online</a>
    </div>
  </div> -->

  <div #mapContainer id="map" style="margin: 0;" itemprop="useMap"></div>
  <div class="container-fluid" id="contact-us-container" itemscope itemtype="https://schema.org/CommunicateAction">
    <div class="col-12" id="contact-container" itemscope itemtype="https://schema.org/EntryPoint">
      <form class="contact-form container-fluid row" [formGroup]="contactUsForm" (ngSubmit)="onSubmit()" aria-label="Contact us form." itemprop="actionApplication">
        <div class="col-12 col-sm-6">
            <label itemprop="contentType">First Name*</label>
            <input class="form-control" type="text" formControlName="firstName" placeholder="e.g. John" aria-label="Input name here." itemprop="actionPlatform" />
        </div>
        <div class="col-12 col-sm-6">
            <label itemprop="contentType">Last Name*</label>
            <input class="form-control" type="text" formControlName="lastName" placeholder="e.g. Smith" aria-label="Input name here." itemprop="actionPlatform" />
        </div>
        <div class="col-12 col-sm-6">
            <label itemprop="contentType">E-Mail*</label>
            <input class="form-control" type="text" formControlName="email" placeholder="e.g. email@domain.com" aria-label="Input e-mail address here." itemprop="actionPlatform" />
        </div>
        <div class="col-12 col-sm-6">
            <label itemprop="contentType">Phone Number*</label>
            <input class="form-control" type="text" formControlName="phone" placeholder="e.g. 123 456 7890" aria-label="Input phone number here." itemprop="actionPlatform" />
        </div>
        <div class="col-12">
            <label itemprop="contentType">Subject*</label>
            <input class="form-control" type="text" formControlName="subject" placeholder="e.g. Hello.." aria-label="Input subject of message here." itemprop="actionPlatform" />
        </div>
        <div class="col-12">
            <label itemprop="contentType">Message*</label>
            <textarea rows="4" col="50" class="form-control" type="text" formControlName="message" placeholder="e.g. A message goes here..." id="message" aria-label="Input message here." itemprop="actionPlatform"></textarea>
        </div>
        <div class="col-12" style="text-align: center;">
            <button class="btn btn-primary" type="submit" [disabled]="!contactUsForm.valid" aria-label="Submit button." itemprop="httpMethod">Submit</button>
        </div>
        <div class="col-12" id="validateForm" *ngIf="!contactUsForm.valid" itemprop="potentialAction">Please complete all fields in the form.</div>
        <div class="col-12" id="validateForm" *ngIf="contactUsForm.valid" itemprop="potentialAction">You may submit the form.</div>
      </form>
    </div>
  </div>

</div>
