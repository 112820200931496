import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

declare const google: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    images = ["../../../assets/bg/dumpster_delivered_ottawa_ohio.jpg", "../../../assets/bg/dumpster_fleet_12yards_16yards.jpg"];

    @ViewChild('mapContainer', {static: false}) gmap: ElementRef;
    map: google.maps.Map;
    lat = 40.728070;
    lng = -84.076690;

    coordinates = new google.maps.LatLng(this.lat, this.lng);

    mapOptions: google.maps.MapOptions = {
        center: this.coordinates,
        zoom: 7,
        disableDefaultUI: true,
        styles: [
        {
        "featureType": "all",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "gamma": 0.5
            }
        ]
        },
        {
        "featureType": "administrative.locality",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#0c0a0a"
            }
        ]
        },
        {
        "featureType": "administrative.locality",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "weight": 0.01
            },
            {
                "color": "#0b0909"
            }
        ]
    },
    {
        "featureType": "administrative.locality",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "weight": 5.09
            },
            {
                "gamma": 0.94
            },
            {
                "hue": "#ff3900"
            }
        ]
    },
    {
        "featureType": "administrative.locality",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#fb1515"
            },
            {
                "saturation": 0
            },
            {
                "lightness": 43
            },
            {
                "gamma": 0.92
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "hue": "#ff0000"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "hue": "#ff0000"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            },
            {
                "color": "#ff0000"
            }
        ]
    },
    {
        "featureType": "road.highway.controlled_access",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "hue": "#ff0000"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ff0000"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#ff0000"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "simplified"
            },
            {
                "color": "#ff0000"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#ff0000"
            }
        ]
    },
    {
        "featureType": "transit.line",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#ff0000"
            }
        ]
    },
    {
        "featureType": "transit.line",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "hue": "#ff0000"
            }
        ]
    },
    {
        "featureType": "transit.line",
        "elementType": "labels.icon",
        "stylers": [
            {
                "hue": "#ff0000"
            }
        ]
    }]};

    // Displays the pop up containing rollawayz contact info
    contentString = '<div id="content">'+
        '<div id="siteNotice">'+
        '</div>'+
        '<h1 id="firstHeading" class="firstHeading" style="color: #393d3f">Rollawayz Dumpster Rentals</h1>'+
        '<div id="bodyContent">'+
        '<h3><span><i class="fas fa-phone-alt"></i></span><span><a href="tel:1-419-615-5886" style="color: rgb(233, 52, 53);">   1-419-615-5886</a></span></h3>'+
        '<h3><span><i class="far fa-envelope"></i></span><span><a href="mailto:rent@rollawayz.com" style="color: rgb(233, 52, 53);">   rent@rollawayz.com</a></span></h3>'+
        '</div>'+
        '</div>';

    infowindow = new google.maps.InfoWindow({
        content: this.contentString
    });

    constructor(private config: NgbCarouselConfig) {
        config.interval = 10000;
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        window.scroll(0,0);
        this.mapInitializer();
    }

    // Initializes all of the above google maps objects
    mapInitializer() {
        this.map = new google.maps.Map(this.gmap.nativeElement, 
        this.mapOptions);
        // this.marker.setMap(this.map);
        // this.cityCircle.setMap(this.map);

        const marker = new google.maps.Marker({
            position: this.coordinates,
            map: this.map
        });
    
        const cityCircle = new google.maps.Circle({
            strokeColor: '#393d3f',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#393d3f',
            fillOpacity: 0.35,
            map: this.map,
            center: { lat: this.lat, lng: this.lng },
            radius: 60000
        });

        this.infowindow.open(this.map, marker);
    }

}
